import type { TableCellProps } from '@mui/material'
import { TableCell } from '@mui/material'
import { Text } from '@orus.eu/pharaoh'
import type { ReactElement } from 'react'

export function TableCellHeader(props: TableCellProps): ReactElement {
  return (
    <TableCell {...props}>
      <Text variant="captionMedium">{props.children}</Text>
    </TableCell>
  )
}
